<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center mb-5">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты анкетирования</h1>
			<div class="flex items-center">
				<Button
					text="Скачать"
					icon="upload"
					class="mr-2"
					color="white"
					spinnerColor="rgba(59, 130, 246, 255)"
					@click.native="upLoadSaveExcel(params)"
					:disabled="loadToExcell"
					:load="loadToExcell"
				/>
				<Button text="Экспорт в Excel" icon="exel" @click.native="upLoadExcel(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="filter">
			<div class="label">Фильтр:</div>
			<div class="flex">
				<div class="input-box flex items-center mr-8">
					<span class="mr-2">Мед. условия</span>
					<v-select :options="medArr" v-model="params.filters[1].fieldName" :reduce="(option) => option.value" @input="getData(params, false)"></v-select>
				</div>
				<div class="input-box flex items-center">
					<span class="mr-2">Тип анкеты</span>
					<v-select :options="anketType" v-model="params.filters[2].value1" :reduce="(option) => option.value" @input="getData(params, false)"></v-select>
				</div>
			</div>
		</div>
		<div class="table-respons">
			<table>
				<thead>
					<tr class="relative z-10">
						<th
							class="bg-white"
							v-for="(item, index) in firstFields"
							:key="'H-' + index"
							:class="{ 'th-sort': item.fieldName, 'sticky left-0 z-10': index === 0 }"
							:rowspan="index !== 2 ? '3' : '1'"
							:colspan="index !== 2 ? '1' : '16'"
						>
							<p class="relative">
								<span @click="switchFilterVisibility(index)" class="cursor-pointer">
									{{ item.Name }}
								</span>
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									position="left"
									:id-filter="index"
									:param="params"
									@query="getData"
									:fields="firstFields"
									:item="item"
								/>
							</p>
						</th>
					</tr>
					<tr>
						<th><p>Открытость и доступность информации об организации</p></th>
						<th colspan="3"><p>Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги</p></th>
						<th colspan="3"><p>Доступность услуг для инвалидов</p></th>
						<th colspan="3"><p>Доброжелательность, вежливость работников медицинской организации</p></th>
						<th colspan="3"><p>Удовлетворенность условиями оказания услуг</p></th>
					</tr>
					<tr>
						<th v-for="(item, index) in fields" :key="'H2-' + index">
							<p class="relative underline">
								<span @click="switchFilterVisibility('AC' + index)" class="cursor-pointer">
									{{ item.Name }}
								</span>

								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									:id-filter="'AC' + index"
									:param="params"
									@query="getData"
									:fields="fields"
									:item="item"
								/>
							</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="item in ankets" :key="item.medicalId">
					<tr :class="{ 'bg-gray-30': item.medicalId === opened }" @click="openRow(item)">
						<td class="sticky left-0 bg-white" :class="{ 'th-sort underline cursor-pointer': item.hasBranch, 'bg-gray-30': item.medicalId === opened }">
							<p>{{ item.name }}</p>
						</td>
						<td>
							<p>{{ item.eachCount }}</p>
						</td>
						<td v-for="(key, index) in fields" :key="'H-' + index">
							<p>{{ item[key.fieldNameView] }}</p>
						</td>
					</tr>
					<template v-if="item.medicalId === opened">
						<tr v-for="subitem in item.branches" :key="subitem.medicalId" :class="{ 'bg-gray-30': item.medicalId === opened }">
							<td class="sticky left-0 bg-gray-30">
								<div class="flex items-center">
									<div v-if="subitem.isBranch" class="default-filial-clone">Филиал</div>
									<div v-if="subitem.isClone" class="default-filial-clone-2 ml-2">Вторичная МО</div>
								</div>
								<p>{{ subitem.name }}</p>
							</td>
							<td>
								<p>{{ subitem.eachCount }}</p>
							</td>
							<td v-for="(key, index) in fields" :key="'H-' + index">
								<p>{{ subitem[key.fieldNameView] }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<center v-if="!loadingTable && ankets.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :totalCount="totalCount" :params="params" @query="getData" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'SurveyResults',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			rowsVisible: 5,
			medArr: [
				{ value: 'isAmbulator', label: 'Амбулаторные' },
				{ value: 'isStacionar', label: 'Стационарные' },
				{ value: 'isSanatoriat', label: 'Санаторно-курортные' },
				{ value: 'isPsychiatric', label: 'Психиатрические больницы' }
			],
			anketType: [
				{ value: '3', label: 'Все' },
				{ value: '2', label: 'Интерактивные' },
				{ value: '1', label: 'Бумажные' }
			],
			firstFields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'eachCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка',
					fieldName: null,
					filterType: null,
					filterValueType: null,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			fields: [
				{
					Name: '1.3',
					fieldNameView: '_1_3',
					fieldName: 'AmbulatorRes_1_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.1',
					fieldNameView: '_2_1',
					fieldName: 'AmbulatorRes_2_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.2',
					fieldNameView: '_2_2',
					fieldName: 'AmbulatorRes_2_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.3',
					fieldNameView: '_2_3',
					fieldName: 'AmbulatorRes_2_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.1',
					fieldNameView: '_3_1',
					fieldName: 'AmbulatorRes_3_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.2',
					fieldNameView: '_3_2',
					fieldName: 'AmbulatorRes_3_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.3',
					fieldNameView: '_3_3',
					fieldName: 'AmbulatorRes_3_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.1',
					fieldNameView: '_4_1',
					fieldName: 'AmbulatorRes_4_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.2',
					fieldNameView: '_4_2',
					fieldName: 'AmbulatorRes_4_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.3',
					fieldNameView: '_4_3',
					fieldName: 'AmbulatorRes_4_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.1',
					fieldNameView: '_5_1',
					fieldName: 'AmbulatorRes_5_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.2',
					fieldNameView: '_5_2',
					fieldName: 'AmbulatorRes_5_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.3',
					fieldNameView: '_5_3',
					fieldName: 'AmbulatorRes_5_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			totalCount: 0,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			ankets: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isAmbulator',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					},
					{
						fieldName: 'VoteType',
						filterType: 1,
						filterValueType: 1,
						value1: '3'
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 5,
				currentPage: 0,
				columns: []
			},
			loadToExcell: false,
			loadingTable: false,
			opened: null
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...localActions,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		upLoadSaveExcel(params) {
			this.loadToExcell = true;
			let saveParam = {};
			params.filters.forEach((item) => {
				if (item.fieldName === 'RegionId') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'YearStr') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'VoteType') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'isAmbulator') {
					saveParam.formTypeId = '111';
					saveParam.name = 'Амбулаторные';
				}
				if (item.fieldName === 'isStacionar') {
					saveParam.formTypeId = '115';
					saveParam.name = 'Стационарные';
				}
				if (item.fieldName === 'isSanatoriat') {
					saveParam.formTypeId = '114';
					saveParam.name = 'Санаторно-курортные';
				}
				if (item.fieldName === 'isPsychiatric') {
					saveParam.formTypeId = '113';
					saveParam.name = 'Психиатрические больницы';
				}
			});
			this.$http
				.get(`Anketa/GetAnketaReportExcel?RegionId=${saveParam.RegionId}&FormTypeId=${saveParam.formTypeId}&paperTypeId=${saveParam.VoteType}&year=${saveParam.YearStr}`, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_${saveParam.name}_${saveParam.YearStr}_.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		upLoadExcel(params) {
			this.loadToExcell = true;
			this.$http
				.post('MedicalOrgs/GetMOAnketResultsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Результаты анкетирования.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		switchFilterVisibility(key) {
			// console.log('switchFilterVisibility', key);
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			// console.log('1', params);

			this.loadingTable = true;
			if (flag) {
				this.params.currentPage = 0;
			}
			// console.log('getData', params.sortOption.sortType);

			// if (params.sortOption.sortType) {
			// 	let formTypeId = '';
			// 	if (params.filters[1].fieldName === 'isAmbulator') formTypeId = 'AmbulatorRes';
			// 	if (params.filters[1].fieldName === 'isStacionar') formTypeId = 'StacionarRes';
			// 	if (params.filters[1].fieldName === 'isSanatoriat') formTypeId = 'SanatoriatRes';
			// 	if (params.filters[1].fieldName === 'isPsychiatric') formTypeId = 'PsychaitricRes';
			// 	params.sortOption.fieldName = formTypeId + params.sortOption.fieldName;
			// }

			// 1ый уровень firstFields
			for (let i = 0; i < this.firstFields.length; i += 1) {
				let FN = this.firstFields[i].fieldName,
					PFN = params.filters[1].fieldName;

				if (FN === 'eachCount' && PFN === 'isAmbulator') this.firstFields[i].fieldName = 'ambulatorcount';
				if (FN === 'eachCount' && PFN === 'isStacionar') this.firstFields[i].fieldName = 'stacionarcount';
				if (FN === 'eachCount' && PFN === 'isSanatoriat') this.firstFields[i].fieldName = 'sanatoriatcount';
				if (FN === 'eachCount' && PFN === 'isPsychiatric') this.firstFields[i].fieldName = 'psychiatriccount';
			}

			// 2ой уровень fields
			for (let i = 0; i < this.fields.length; i += 1) {
				let FN = this.fields[i].fieldName,
					PFN = params.filters[1].fieldName;

				if (PFN === 'isAmbulator') this.fields[i].fieldName = `AmbulatorRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isStacionar') this.fields[i].fieldName = `StacionarRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isSanatoriat') this.fields[i].fieldName = `SanatoriatRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isPsychiatric') this.fields[i].fieldName = `PsychaitricRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
			}

			this.getMOAnketResults(params).then((res) => {
				this.ankets = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
		}
	},
	mounted() {
		let regionId = this.getRegionId();

		this.params.filters[0].value1 = regionId;

		this.getRegionYearData(regionId).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, false);
		});
	}
};
</script>

<style scoped>
.default-filial-clone,
.default-filial-clone-2 {
	padding: 4px 8px;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	background: #a8b1ce;
	border-radius: 4px;
	width: 110px;
	color: #fff;
	text-align: center;
}
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 500px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	/* cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}

.label {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #a8b1ce;
	margin-bottom: 12px;
}

.input-box {
	font-weight: 600;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
}
</style>
